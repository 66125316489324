/*!

=========================================================
* Now UI Kit PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// import Main from "views/Main.js";

// company page
// import CompanyOnePage from "views/CompanyOnePage.js";

// solution page
// import Solution from "views/Solution.js";

// others
// import ContactUs from "views/ContactUs.js";

//i18n
import "./config/lang/i18n";
// import WhiteNavbar from "components/Navbars/WhiteNavbar";
import TheLayout from "./components/TheLayout";
import "./reset.scss";
import "./index.scss";
import "./sass/main.scss";

const rootNode = document.getElementById("root");
ReactDOM.createRoot(rootNode).render(
  <BrowserRouter>
    <TheLayout />
  </BrowserRouter>
);
