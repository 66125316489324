import React from "react";
import { Container } from "reactstrap";

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container>
          <hr />
          <div className="company-info">
            <span>회사명 : 시프트원 주식회사</span>
            <span>대표자 : 이근주, 이태재</span>
            <span>
              주소 : 서울특별시 강남구 역삼동 749-14 배영빌딩 201호 (역삼로 17길
              9)
            </span>
            <span>이메일 : contact@shiftone.kr</span>
            <span>전화 : 02-2135-1551</span>
            <span>사업자등록번호 : 112-86-01043</span>
          </div>
          <p>© 2023 Shiftone Co., Ltd. All Rights Reserved.</p>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
