import React from "react";
import Navigation from "./Navbars/Navigation";
import Footer from "./Footers/Footer";
import TheContent from "./TheContent";

const TheLayout = () => {
  return (
    <div className="layout">
      <Navigation />
      <TheContent />
      <Footer />
    </div>
  );
};
export default TheLayout;
