const routes = [
  { path: "/", exact: true, name: "홈" },
  { path: "/company", exact: true, name: "회사소개" },
  /* {
    path: "/business",
    exact: true,
    name: "사업소개",
  }, */
  {
    path: "/contact-us",
    exact: true,
    name: "문의하기",
  },
];
export default routes;
