import React, { useRef, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { motion, useCycle, useAnimation } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./MenuToggle";
import { MenuItem } from "./MenuItem";
import routes from "../../routes";

const logoVariants = {
  visible: { x: 0, opacity: 1, transition: { duration: 0.07 } },
  hidden: { x: -5, opacity: 0, transition: { duration: 0.07 } },
};
const sidebarVariants = {
  open: (height = 700) => ({
    clipPath: `circle(${height * 2 + 200}px at calc(100% - 42px) 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at calc(100% - 42px) 40px)",
    transition: {
      delay: 0.1,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const menuVariants = {
  open: {
    zIndex: 20,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    zIndex: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

function Navigation() {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const logoControl = useAnimation();
  const { pathname } = useLocation();

  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scroll) {
      logoControl.start("hidden");
    } else {
      logoControl.start("visible");
    }
  }, [logoControl, scroll]);

  return (
    <>
      <NavLink to="/">
        <motion.img
          className="nav-logo"
          initial="hidden"
          animate={logoControl}
          variants={logoVariants}
          src={
            pathname === "/"
              ? require("assets/img/custom/shiftone_logo_white3.png")
              : require("assets/img/shiftone_logo_blue3.png")
          }
          alt="..."
        />
      </NavLink>
      <motion.nav
        className={isOpen ? "navigation-motion-div" : "hidden"}
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <motion.div className="background" variants={sidebarVariants} />
        <motion.ul
          className={isOpen ? "open-menu" : "close-menu"}
          variants={menuVariants}
        >
          {routes.map(({ path, name }) => (
            <NavLink to={path} key={path} className="menu-item">
              <MenuItem name={name} key={name} toggle={() => toggleOpen()} />
            </NavLink>
          ))}
        </motion.ul>
        <MenuToggle toggle={() => toggleOpen()} />
      </motion.nav>
    </>
  );
}

export default Navigation;
