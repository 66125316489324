import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router";

const MainPage = lazy(() => import("views/MainPage"));
const AboutCompany = lazy(() => import("views/AboutCompany"));
// const AboutBusiness = lazy(() => import("views/AboutBusiness"));
const ContactUs = lazy(() => import("views/ContactUs"));

const TheContent = () => {
  return (
    <Suspense fallback={<div style={{ height: "100vh" }} />}>
      <main>
        <Routes>
          <Route path="/" exact element={<MainPage />} />
          <Route path="/company" exact element={<AboutCompany />} />
          {/* <Route path="/business" exact element={<AboutBusiness />} /> */}
          <Route path="/contact-us" exact element={<ContactUs />} />
        </Routes>
      </main>
    </Suspense>
  );
};
export default TheContent;
